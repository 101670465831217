import {get, post, Ws} from './api'

const GeneralService = {
    getFAQ: (id?: string) => get(`/faq${id ? `/${id}` : ''}`),
    getNews: (id?: string) => get(`/news${id ? `/${id}` : ''}`),
    getDiscounts: () => get('/promotion'),
    getPage: (name: string) => get(`/page/${name}`),
    getCities: () => get('/cities'),
    getOrganisations: () => get('/organisations'),
    getCurrencies: () => get('/currencies'),
    searchFor: (word: string, signal: any, q: boolean) => get(`/${q ? 'q' : ''}search/${encodeURI(word)}`, {signal}),
    getPolls: () => get('/polls'),
    addUserAnswer: (answer: any) => post(`/polls`, answer),
    getBanners: (params: any) => post('/settings/banners', params),
    getSettings: () => get('/settings/list'),
    getPopups: () => get('/settings/popups'),
    getNotification: () => Ws('/notifications'),
    connectCurrentPage: (params: any) => Ws(`/page`, params),
}

export const GeneralRequestsEnum = {
    getFAQ: (id?: string) => `/faq/${id ?? ''}`,
    getNews: (id?: string) => `/news/${id ?? ''}`,
    getDiscounts: '/promotion',
    getPage: (name: string) => `/page/${name}`,
    getCities: '/cities',
    getOrganisations: '/organisations',
    getCurrencies: '/currencies',
    searchFor: (id: string) => `/${id === 'quickSearch' ? 'q' : ''}search`,
    getPolls: '/polls',
    addUserAnswer: () => `/polls`,
    getBanners: '/settings/banners',
    getSettings: '/settings/list',
    getPopups: '/settings/popups',
}

export default GeneralService
