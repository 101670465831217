import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider as StoreProvider} from 'react-redux'
import {ThemeProvider} from '@mui/material/styles'
import {store} from './store/store'
import theme from './styles/theme'
import {AxiosInterceptorsSetup} from './api/setupInterceptors'
import {RouterProvider} from './app/providers/RouterProvider'
import './styles/index.scss'
import * as Sentry from "@sentry/react";
import {browserTracingIntegration} from "@sentry/react";

Sentry.init({
    dsn: "https://1169b570e23a4a0618625ac573230162@sentry.b2bit.by/3",
    environment: process.env.NODE_ENV,
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 1.0,
});

AxiosInterceptorsSetup(store.dispatch)

// eslint-disable-next-line react/no-deprecated
createRoot(document.getElementById('root')).render(
    <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
            <RouterProvider />
        </ThemeProvider>
    </StoreProvider>
);
